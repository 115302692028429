import React from 'react';
import '../../style/how-it-works.scss';
import {Nav} from "../../components";
import Footer from "../../components/footer";
import {Button} from "../../components/common";
import One from '../../images/how-it-works/1.svg';
import Two from '../../images/how-it-works/2.svg';
import Three from '../../images/how-it-works/3.svg';
import ProvideDetails from '../../images/how-it-works/provide-details.svg'
import PrepareDevice from '../../images/how-it-works/prepare-device.svg'
import GetPaid from '../../images/how-it-works/get-paid.svg'
import LineOne from '../../images/how-it-works/line1.svg'
import LineTwo from '../../images/how-it-works/line2.svg'
import LongBox from "../../images/how-it-works/landing-section/long_box.svg";
import SmallMobilePhone from "../../images/how-it-works/landing-section/small_mobile_phone.svg";
import SquareBox from "../../images/how-it-works/landing-section/square_box.svg";
import ThinBox from "../../images/how-it-works/landing-section/thin_box.svg";
import CrossIcon from '../../images/how-it-works/cross-icon.svg';
import CheckIcon from '../../images/how-it-works/check-icon.svg';
import SwaptecsLogo from '../../images/logo.svg';
import EbayLogo from '../../images/how-it-works/ebay-logo.svg'
import CraigslistLogo from '../../images/how-it-works/craigslist-logo.svg'
import {openUrl} from "../../utils";
import {APP_URL} from "../../data/consts";

const explanationSectionText = [
    "No overthinking, instant sale",
    "Sell without leaving home",
    "Know your sell price upfront",
    "No hidden fees or haggling",
    "No interaction with anonymous users"
]

const HowItWorks = ({location}) => {
    return <div className='siteWrapper'>
        <Nav
            location={location}
            title={'How Swaptecs works'}
            darkNav={true}
        />

        <section className={'hiw-landing-section'}>
            <div className={'content-container'}>
                <h1>
                    Exchange your phone for cash in 3 steps
                </h1>

                <Button
                    label={'Get your quote'}
                    className={'primary filled'}
                    onClick={() => openUrl(APP_URL)}
                />

                <img className="illustration-item long-box-image" src={LongBox} alt="Long Box image"/>
                <img className="illustration-item bottom-right-phone" src={SmallMobilePhone}
                     alt="Small Mobile Phone image"/>
                <img className="illustration-item left-square-box" src={SquareBox} alt="Square Box image"/>
                <img className="illustration-item middle-box" src={SquareBox} alt="Square Box image"/>
                <img className="illustration-item right-upper-box" src={SquareBox} alt="Square Box image"/>
                <img className="illustration-item upper-left-phone" src={SmallMobilePhone}
                     alt="Small Mobile Phone image"/>
                <img className="illustration-item bottom-left-box" src={ThinBox} alt="Thin box image"/>
            </div>
        </section>

        <section className={'infographics'}>
            <div className="content-container">
                <div className={'infographics-item'}>
                    <div className="numeric-icon-container">
                        <img src={One} alt="One" className="numeric-icon"/>
                    </div>
                    <div className="text">
                        <h3>
                            Give us details<br/> about your phone<br/> and get an offer
                        </h3>
                        <p>
                            Select the model of device you want to trade in, tell us the details like the, capacity,
                            carrier and condition. Our filters make it easier than ever.
                            We’ll give an offer based on the details you left.
                        </p>
                    </div>
                    <div className="icon">
                        <img src={ProvideDetails} alt=""/>
                    </div>

                    <img src={LineOne} className={'line lineOne'} alt=""/>
                </div>

                <div className={'infographics-item'}>
                    <div className="numeric-icon-container">
                        <img src={Two} alt="Two" className="numeric-icon"/>
                    </div>
                    <div className="text">
                        <h3>
                            Prepare and ship<br/> us your phone
                        </h3>
                        <p>
                            We will send you instructions to help to reset your phone. You will get a free shipping
                            label to ship in your phone using your own box. We can provide you with a box
                            for a cost upon request.
                        </p>
                    </div>
                    <div className="icon">
                        <img src={PrepareDevice} alt=""/>
                    </div>

                    <img src={LineTwo} className={'line lineTwo'} alt=""/>
                </div>

                <div className={'infographics-item'}>
                    <div className="numeric-icon-container">
                        <img src={Three} alt="Three" className="numeric-icon"/>
                    </div>
                    <div className="text">
                        <h3>
                            Get paid as soon<br/>
                            as possible in cash<br/>
                            or via PayPal
                        </h3>
                        <p>
                            Once we have received your phone, we inspect it to ensure that everything matches with the
                            details and condition you provided. If everything looks good,
                            we’ll send you money via PayPal.
                        </p>
                    </div>
                    <div className="icon">
                        <img src={GetPaid} alt=""/>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="cta row">
                <h3>
                    It’s easy to get paid when you sell devices through Swaptecs
                </h3>

                <Button
                    className={'big primary white width-220'}
                    label={'Get your quote'}
                    onClick={() => openUrl(APP_URL)}
                />
            </div>
        </section>

        <section>
            <div className={'explanation'}>
                <div className="logo-headers">
                    <div className="logo-container swaptecs">
                        <img src={SwaptecsLogo} alt="Swaptecs Logo"/>
                    </div>
                    <div className="logo-container ebay">
                        <img src={EbayLogo} alt="Ebay Logo"/>
                    </div>
                    <div className="logo-container craigslist">
                        <img src={CraigslistLogo} alt="Craigs List logo"/>
                    </div>
                </div>

                <div className="explanation-table">
                    {explanationSectionText.map(text => <div key={text} className={'table-row'}>
                        <p>{text}</p>
                        <span className={'swaptecs-cell'}><img src={CheckIcon} alt="Checked"/></span>
                        <span className={'ebay-cell'}><img src={CrossIcon} alt="Checked"/></span>
                        <span className={'craigslist-cell'}><img src={CrossIcon} alt="Checked"/></span>
                    </div>)}
                </div>
            </div>
        </section>

        <Footer/>
    </div>
}

export default HowItWorks;